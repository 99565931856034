import { v4 as uuidv4 } from 'uuid';

import { LocalStorage, QueryParameters } from '../../tokens';

const getInternalLink = (pageName, page, link = '', baseUrl = '') => {
  if (typeof page === 'string') {
    if (page.includes('mailto')) {
      return page;
    }
    return `${page}/`;
  }
  let newLink = link;
  if (!page && !link) {
    console.warn(`⚠️ No page found for '${pageName}'`);
    return '404/';
  }
  if (page.slug && page.slug !== '#') {
    newLink = link ? `${page.slug}/${link}` : page.slug;
  }
  if (page.parentPage) {
    return getInternalLink(pageName, page.parentPage, newLink, baseUrl);
  }

  return baseUrl ? `${baseUrl}/${newLink}/` : `${newLink}/`;
};

const parseRedirect = (redirect) => {
  if (
    (!redirect.from && !redirect.fromSlug) ||
    (!redirect.to && !redirect.toUrl)
  ) {
    console.warn(
      `Redirect model ${redirect.name} misses either a valid from or to field.`
    );
    return null;
  }

  const from = redirect.from
    ? `/${getInternalLink(redirect.pageName, redirect.from)}`
    : `/${redirect.fromSlug}`;
  const to = redirect.to
    ? `/${getInternalLink(redirect.pageName, redirect.to)}`
    : redirect.toUrl;

  // When a linked page, for example the homepage is linked by accident, disallow redirect.
  if (from === '/') {
    console.warn(`Redirect model "${redirect.name}" cannot redirect from /.`);
    return null;
  }

  return {
    from,
    to,
  };
};

const parseRedirects = (redirects) =>
  redirects.map((redirect) => parseRedirect(redirect)).filter(Boolean);

const parseRedirectURL = (redirect, location) => {
  let baseUrl =
    redirect.to.slice(0, 4) === 'http'
      ? redirect.to
      : `${location.origin}${redirect.to}`;

  if (baseUrl.includes(`?${QueryParameters.TPCI}`)) {
    const tpciID = localStorage.getItem(LocalStorage.TPCI_ID) || uuidv4();

    localStorage.setItem(LocalStorage.TPCI_ID, tpciID);

    baseUrl = baseUrl.replace('{ID}', tpciID);
  }

  return baseUrl;
};

export { parseRedirects, parseRedirectURL };
